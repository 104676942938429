import React from "react";
import "../App.css";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <div class="herobackgroundContainter" />

      <div className="logoContainer">
        <h1>PLAN 4 GREEN</h1>
        <p className="hero-text">
          The interactive mobile optimizer to create your future energy plan.
        </p>
        <p className="hero-sub-text">Comming soon...</p>
      </div>

      <div className="hero-btns"></div>
    </div>
  );
}

export default HeroSection;
