import React from "react";
import "./Navbar.css";

function Navbar() {
  return (
    <>
      <nav className="navbar">
        <img
          className="navbar-logo"
          src="p4gLogoV1.svg"
          alt="Plan4Green Logo"
        />
        <div className="navbar-container"></div>
      </nav>
    </>
  );
}

export default Navbar;
